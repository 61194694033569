<template>
  <div class="component-profile">
    <div v-if="dataUser" class="w-100">
      <div class="container-data-perfil">
        <div class="container-info-perfil col-12 col-md-8">
          <div class="w-100 d-flex">
            <button
              class="ms-auto mb-4 mb-md-2 bnt-outline"
              @click="editadata = !editadata"
            >
              {{ editadata ? 'Cancelar' : 'Editar mis datos' }}
            </button>
          </div>
          <div v-if="!editadata">
            <p>
              <b>Correo electrónico: </b>{{ dataUser.persona.correo }}<br />
              <b>Nombre: </b> {{ dataUser.persona.nombres }}<br />
              <b>Apellidos: </b> {{ dataUser.persona.apellidos }}<br />
              <b>Cédula de ciudadanía: </b> {{ dataUser.persona.documento
              }}<br />
              <b>Celular de contacto: </b> {{ dataUser.persona.celular }}<br />
              <b>Fecha de cumpleaños: </b>
              {{
                dataUser.persona.fechanac_dia
                  ? dataUser.persona.fechanac_dia
                  : '-'
              }}
              de
              {{
                dataUser.persona.fechanac_mes
                  ? getMonth(dataUser.persona.fechanac_mes)
                  : '-'
              }}<br />
              <b>Genero: </b>
              {{ dataUser.persona.genero == 'M' ? 'Hombre' : 'Mujer' }}<br />
            </p>
          </div>
          <div v-else>
            <h3 class="mb-4">Editar datos personales</h3>
            <div class="d-flex flex-wrap">
              <div class="col-12 mb-4 px-md-3">
                <div class="input-simple-clover">
                  <label
                    class="label-input"
                    :style="
                      dataUser.persona.correo
                        ? 'top: -14px;'
                        : 'opacity: 0; z-index: -1;'
                    "
                    >Correo electrónico</label
                  >
                  <input
                    v-model="dataUser.persona.correo"
                    disabled
                    placeholder="Correo electrónico"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-4 px-md-3">
                <div class="input-simple-clover">
                  <label
                    :class="validateName ? 'invalid' : ''"
                    class="label-input"
                    :style="
                      nombres ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
                    "
                    >Nombres</label
                  >
                  <input
                    v-model="nombres"
                    :class="validateName ? 'invalid-error' : ''"
                    placeholder="Nombres"
                    type="text"
                    autocomplete="given-name"
                    @blur="validateBlur('validateName')"
                    @focus="validateName = false"
                  />
                  <span v-if="validateName" class="inavalida-input">{{
                    nombres ? 'Nombre invalido' : 'Campo requerido'
                  }}</span>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-4 px-md-3">
                <div class="input-simple-clover">
                  <label
                    :class="validateApellido ? 'invalid' : ''"
                    class="label-input"
                    :style="
                      apellidos ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
                    "
                    >Apellidos</label
                  >
                  <input
                    v-model="apellidos"
                    :class="validateApellido ? 'invalid-error' : ''"
                    placeholder="Apellidos"
                    type="text"
                    autocomplete="family-name"
                    @blur="validateBlur('validateApellido')"
                    @focus="validateApellido = false"
                  />
                  <span v-if="validateApellido" class="inavalida-input">{{
                    apellidos ? 'Apellido invalido' : 'Campo requerido'
                  }}</span>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-4 px-md-3">
                <div class="input-simple-clover">
                  <label
                    :class="validateTipoDoc ? 'invalid' : ''"
                    class="label-input"
                    :style="
                      tipodoc ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
                    "
                    >Tipo</label
                  >
                  <select
                    v-model="tipodoc"
                    :class="validateTipoDoc ? 'invalid-error' : ''"
                    type="number"
                    class="w-100"
                    @blur="validateBlur('validateTipoDoc')"
                    @focus="validateTipoDoc = false"
                  >
                    <option :value="''">Tipo</option>
                    <option value="CC">CC</option>
                    <option value="CE">CE</option>
                    <option value="PAS">PAS</option>
                    <option value="NIT">NIT</option>
                  </select>
                  <span v-if="validateTipoDoc" class="inavalida-input"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-4 px-md-3">
                <div class="input-simple-clover">
                  <label
                    :class="validateDoc ? 'invalid' : ''"
                    class="label-input"
                    :style="
                      documento ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
                    "
                    >Documento</label
                  >
                  <input
                    v-model="documento"
                    :class="validateDoc ? 'invalid-error' : ''"
                    placeholder="Documento"
                    type="number"
                    @blur="validateBlur('validateDoc')"
                    @focus="validateDoc = false"
                  />
                  <span v-if="validateDoc" class="inavalida-input">{{
                    documento ? 'Documento invalido' : 'Campo requerido'
                  }}</span>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-4 px-md-3">
                <div class="input-simple-clover">
                  <label
                    :class="validatePhone ? 'invalid' : ''"
                    class="label-input"
                    :style="
                      celular ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
                    "
                    >Celular de contacto</label
                  >
                  <input
                    v-model="celular"
                    type="number"
                    min="0"
                    placeholder="Celular de contacto"
                    autocomplete="tel"
                    :class="validatePhone ? 'invalid-error' : ''"
                    @blur="validateBlur('validatePhone')"
                    @focus="validatePhone = false"
                  />
                  <span v-if="validatePhone" class="inavalida-input">{{
                    celular ? 'Celular invalido' : 'Campo requerido'
                  }}</span>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-4 px-md-3">
                <div class="input-simple-clover">
                  <label
                    :class="validateGender ? 'invalid' : ''"
                    class="label-input"
                    :style="genero ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
                    >Tipo</label
                  >
                  <select
                    v-model="genero"
                    :class="validateGender ? 'invalid-error' : ''"
                    type="number"
                    class="w-100"
                    @blur="validateBlur('validateGender')"
                    @focus="validateGender = false"
                  >
                    <option :value="''">Genero</option>
                    <option value="M">Hombre</option>
                    <option value="F">Mujer</option>
                    <option value="O">Otro</option>
                  </select>
                  <span v-if="validateGender" class="inavalida-input"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-4 px-md-3">
                <div class="input-simple-clover">
                  <label
                    :class="validateDay ? 'invalid' : ''"
                    class="label-input"
                    :style="
                      fechanac_dia ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
                    "
                    >Día</label
                  >
                  <select
                    v-model="fechanac_dia"
                    :class="validateDay ? 'invalid-error' : ''"
                    type="number"
                    class="w-100"
                    @blur="validateBlur('validateDay')"
                    @focus="validateDay = false"
                  >
                    <option :value="''">Día</option>
                    <option value="01">1</option>
                    <option value="02">2</option>
                    <option value="03">3</option>
                    <option value="04">4</option>
                    <option value="05">5</option>
                    <option value="06">6</option>
                    <option value="07">7</option>
                    <option value="08">8</option>
                    <option value="09">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                  <span v-if="validateDay" class="inavalida-input"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-4 px-md-3">
                <div class="input-simple-clover">
                  <label
                    :class="validateMonth ? 'invalid' : ''"
                    class="label-input"
                    :style="
                      fechanac_mes ? 'top: -14px;' : 'opacity: 0; z-index: -1;'
                    "
                    >Mes</label
                  >
                  <select
                    v-model="fechanac_mes"
                    :class="validateMonth ? 'invalid-error' : ''"
                    type="number"
                    class="w-100"
                    @blur="validateBlur('validateMonth')"
                    @focus="validateMonth = false"
                  >
                    <option :value="''">Mes</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </select>
                  <span v-if="validateMonth" class="inavalida-input"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-center w-100">
                <div class="col-12 col-lg-4 d-flex px-md-3 mb-4 mb-lg-0">
                  <button class="w-100 bnt-outline" @click="editadata = false">
                    Cancelar
                  </button>
                </div>
                <div class="col-12 col-lg-4 d-flex px-md-3">
                  <button class="w-100 bnt-outline" @click="validateSendData()">
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="@/assets/imgs/img-login-portada.png"
          class="col-12 col-md-4 ps-md-3 mb-auto"
          alt=""
        />
      </div>
    </div>
    <div v-else class="text-center">
      <h3>Aún no has iniciado sesión</h3>
      <p>Para poder acceder a este modulo primero debes iniciar sesión.</p>
      <button class="bnt-primary" @click="$bvModal.show('modal-login')">
        Iniciar sesión
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      nombres: '',
      apellidos: '',
      documento: '',
      tipodoc: '',
      celular: '',
      genero: '',
      fechanac_dia: '',
      fechanac_mes: '',
      validateName: false,
      validateApellido: false,
      validateTipoDoc: false,
      validateDoc: false,
      validatePhone: false,
      validateGender: false,
      validateDay: false,
      validateMonth: false,
      editadata: false,
    }
  },
  computed: {
    ...mapState('user', ['dataUser', 'errorUpdateInfo', 'msgErrorUpdateInfo']),
    validateDocUser() {
      if (this.tipodoc == 'CC') {
        const re1 = /([0-9]){7}/
        return re1.test(this.documento)
      } else if (this.tipodoc == 'CE') {
        const re2 = /([0-9]){5}/
        return re2.test(this.documento)
      } else if (this.tipodoc == 'PAS') {
        const re3 = /([0-9A-Za-z]){6}/
        return re3.test(this.documento)
      } else if (this.tipodoc == 'NIT') {
        return this.documento.length >= 5
      }
      return false
    },
    validateTel() {
      const re = /^3[0-9]{9}/
      return re.test(this.celular) && this.celular.length < 11
    },
  },
  watch: {
    editadata() {
      this.validateName = false
      this.validateApellido = false
      this.validateTipoDoc = false
      this.validateDoc = false
      this.validatePhone = false
      this.validateGender = false
      this.validateDay = false
      this.validateMonth = false
      this.nombres = this.dataUser.persona.nombres
      this.apellidos = this.dataUser.persona.apellidos
      this.documento = this.dataUser.persona.documento
      this.tipodoc = this.dataUser.persona.tipodoc
      this.celular = this.dataUser.persona.celular
      this.genero = this.dataUser.persona.genero
      this.fechanac_dia = this.dataUser.persona.fechanac_dia
      this.fechanac_mes = this.dataUser.persona.fechanac_mes
    },
  },
  methods: {
    ...mapActions('user', ['updateDataUser']),
    validateBlur(input) {
      if (input == 'validateName') {
        this.validateName = !(this.nombres.length > 2)
      } else if (input == 'validateApellido') {
        this.validateApellido = !(this.apellidos.length > 2)
      } else if (input == 'validateTipoDoc') {
        this.validateTipoDoc = !this.tipodoc
      } else if (input == 'validateDoc') {
        this.validateDoc = !this.validateDocUser
      } else if (input == 'validatePhone') {
        this.validatePhone = !this.validateTel
      } else if (input == 'validateGender') {
        this.validateGender = !this.genero
      } else if (input == 'validateDay') {
        this.validateDay = !this.fechanac_dia
      } else if (input == 'validateMonth') {
        this.validateMonth = !this.fechanac_mes
      }
    },
    async validateSendData() {
      if (
        this.nombres.length > 2 &&
        this.apellidos.length > 2 &&
        this.tipodoc &&
        this.validateDocUser &&
        this.validateTel &&
        this.genero &&
        this.fechanac_dia &&
        this.fechanac_mes
      ) {
        await this.updateDataUser({
          nombres: this.nombres,
          apellidos: this.apellidos,
          documento: this.documento,
          tipoDoc: this.tipodoc,
          celular: this.celular,
          genero: this.genero,
          day: this.fechanac_dia,
          month: this.fechanac_mes,
        }).then(() => {
          if (!this.errorUpdateInfo) {
            this.editadata = false
          }
        })
      } else {
        this.validateName = !(this.nombres.length > 2)
        this.validateApellido = !(this.apellidos.length > 2)
        this.validateTipoDoc = !this.tipodoc
        this.validateDoc = !this.validateDocUser
        this.validatePhone = !this.validateTel
        this.validateGender = !this.genero
        this.validateDay = !this.fechanac_dia
        this.validateMonth = !this.fechanac_mes
      }
    },
    getMonth(month) {
      if (month == '01') {
        return 'Enero'
      } else if (month == '02') {
        return 'Febrero'
      } else if (month == '03') {
        return 'Marzo'
      } else if (month == '04') {
        return 'Abril'
      } else if (month == '05') {
        return 'Mayo'
      } else if (month == '06') {
        return 'Junio'
      } else if (month == '07') {
        return 'Julio'
      } else if (month == '08') {
        return 'Agosto'
      } else if (month == '09') {
        return 'Septiembre'
      } else if (month == '10') {
        return 'Octubre'
      } else if (month == '11') {
        return 'Noviembre'
      } else if (month == '12') {
        return 'Diciembre'
      } else {
        return month
      }
    },
  },
}
</script>
